import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"

export function GenericTooltip({
  header,
  children,
}: {
  header: React.ReactNode
  children: React.ReactNode
}) {
  return (
    <Box>
      <Header>{header}</Header>
      <Content color="tertiary">{children}</Content>
    </Box>
  )
}

export const Box = styled.div`
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  min-width: 170px;
  font-family: Figtree, sans-serif;
`

export const Header = styled(MText)`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.875rem 1rem;
  background: ${mColors.primaryLight};
`

export const Content = styled(MText)`
  background: white;
  padding: 0.875rem 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
`

import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import { GenericTooltip } from "./GenericTooltip"

export function ChartTooltip({
  date,
  label,
  value,
  unit,
  minMaxIncluded,
  low,
  high,
}: {
  date: string
  label: string
  value: string | number
  unit: string
  minMaxIncluded?: boolean
  low?: string
  high?: string
}) {
  return (
    <GenericTooltip header={date}>
      <Row>
        <span>
          <span>{label + ": "}</span>
          <span>
            <Value>{value} </Value>
          </span>
          <span>{unit}</span>
        </span>
      </Row>
      {minMaxIncluded && (
        <Row>
          <span>
            <DownArrow>↓</DownArrow> <Value>{low ? low + unit : "-"}</Value>
          </span>
          <span>
            <UpArrow>↑</UpArrow> <Value>{high ? high + unit : "-"}</Value>
          </span>
        </Row>
      )}
    </GenericTooltip>
  )
}

export const DownArrow = styled.span`
  color: ${mColors.systemGood};
`

export const UpArrow = styled.span`
  color: ${mColors.systemError};
`

export const Value = styled.span`
  font-weight: bold;
`

export const Row = styled.div`
  display: flex;
  gap: ${spacing.M};
`
